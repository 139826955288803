import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Button,
  IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useStickerContext } from '../context/StickerContext';

const UserProfile = () => {
  const navigate = useNavigate();
  const { packages, deletePackage } = useStickerContext();

  return (
    <Box>
      <Typography variant="h4" sx={{ mb: 3 }}>
        My Sticker Packages
      </Typography>
      <Grid container spacing={3}>
        {packages.map((pkg) => (
          <Grid item xs={12} sm={6} md={4} key={pkg.id}>
            <Card 
              sx={{ 
                cursor: 'pointer',
                transition: 'all 0.2s ease-in-out',
                '&:hover': {
                  transform: 'scale(1.02)',
                  boxShadow: 3,
                },
                aspectRatio: '1',
                display: 'flex',
                flexDirection: 'column',
              }}
              onClick={() => navigate(`/package/${pkg.id}`)}
            >
              <Box sx={{ 
                flexGrow: 1, 
                display: 'grid', 
                gridTemplateColumns: 'repeat(2, 1fr)', 
                gap: 1, 
                p: 1,
                backgroundColor: 'rgba(37, 211, 102, 0.05)',
              }}>
                {pkg.stickers.slice(0, 4).map((sticker) => (
                  <CardMedia
                    key={sticker.id}
                    component="img"
                    image={sticker.imageUrl}
                    alt=""
                    sx={{
                      objectFit: 'contain',
                      aspectRatio: '1',
                      padding: 0.5,
                    }}
                  />
                ))}
                {pkg.stickers.length > 4 && (
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: 8,
                      right: 8,
                      backgroundColor: 'rgba(0, 0, 0, 0.7)',
                      color: 'white',
                      borderRadius: '50%',
                      width: 24,
                      height: 24,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '0.75rem',
                    }}
                  >
                    +{pkg.stickers.length - 4}
                  </Box>
                )}
              </Box>
              <CardContent sx={{ p: 2, pt: 1 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography variant="subtitle1" noWrap>
                    {pkg.name}
                  </Typography>
                  <IconButton 
                    color="error" 
                    onClick={(e) => {
                      e.stopPropagation();
                      deletePackage(pkg.id);
                    }}
                    size="small"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
                <Typography variant="body2" color="text.secondary">
                  {pkg.stickers.length} stickers
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
        {packages.length === 0 && (
          <Grid item xs={12}>
            <Typography variant="body1" color="text.secondary" align="center">
              No packages yet. Create your first package in the gallery!
            </Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default UserProfile; 