import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/Download';
import { useStickerContext } from '../context/StickerContext';
import JSZip from 'jszip';

interface Sticker {
  id: string;
  imageUrl: string;
}

// Generate array of 30 stickers using the actual files
const mockStickers: Sticker[] = Array.from({ length: 30 }, (_, index) => ({
  id: String(index + 1),
  imageUrl: `/stickers/sticker_${index + 1}.webp`,
}));

const StickerPackage = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { getPackage, deletePackage, updatePackage } = useStickerContext();
  const [packageData, setPackageData] = useState<{ id: string; name: string; stickers: Sticker[] } | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedStickers, setSelectedStickers] = useState<Set<string>>(new Set());
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    if (id) {
      const pkg = getPackage(id);
      if (pkg) {
        setPackageData(pkg);
        setSelectedStickers(new Set(pkg.stickers.map(s => s.id)));
      } else {
        navigate('/profile');
      }
    }
  }, [id, getPackage, navigate]);

  const handleStickerSelect = (stickerId: string) => {
    const newSelected = new Set(selectedStickers);
    if (newSelected.has(stickerId)) {
      newSelected.delete(stickerId);
    } else {
      newSelected.add(stickerId);
    }
    setSelectedStickers(newSelected);
  };

  const handleSave = () => {
    if (id && packageData) {
      const selectedStickerItems = mockStickers.filter(sticker => 
        selectedStickers.has(sticker.id)
      );
      updatePackage(id, selectedStickerItems);
      setIsEditing(false);
      setPackageData(prev => prev ? { ...prev, stickers: selectedStickerItems } : null);
    }
  };

  const handleDelete = () => {
    if (id) {
      deletePackage(id);
      navigate('/profile');
    }
  };

  const handleDownload = async () => {
    if (!packageData) return;

    setIsDownloading(true);
    try {
      const zip = new JSZip();
      
      // Download all stickers and add them to the zip
      const downloadPromises = packageData.stickers.map(async (sticker) => {
        const response = await fetch(sticker.imageUrl);
        const blob = await response.blob();
        zip.file(`sticker_${sticker.id}.webp`, blob);
      });

      await Promise.all(downloadPromises);

      // Generate the zip file
      const content = await zip.generateAsync({ type: 'blob' });
      
      // Create a download link and trigger the download
      const url = window.URL.createObjectURL(content);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${packageData.name.toLowerCase().replace(/\s+/g, '_')}_stickers.zip`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading package:', error);
      // You might want to show an error message to the user here
    } finally {
      setIsDownloading(false);
    }
  };

  if (!packageData) {
    return null;
  }

  return (
    <Box>
      <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h4">{packageData.name}</Typography>
        <Box>
          <IconButton 
            color="primary" 
            onClick={() => setIsEditing(true)}
            sx={{ mr: 1 }}
          >
            <EditIcon />
          </IconButton>
          <IconButton 
            color="error" 
            onClick={handleDelete}
            sx={{ mr: 1 }}
          >
            <DeleteIcon />
          </IconButton>
          <Button
            variant="contained"
            color="primary"
            startIcon={isDownloading ? <CircularProgress size={20} color="inherit" /> : <DownloadIcon />}
            onClick={handleDownload}
            disabled={isDownloading}
          >
            {isDownloading ? 'Downloading...' : 'Download Package'}
          </Button>
        </Box>
      </Box>

      <Grid container spacing={2}>
        {packageData.stickers.map((sticker) => (
          <Grid item xs={6} sm={4} md={3} lg={2} key={sticker.id}>
            <Card sx={{ aspectRatio: '1' }}>
              <CardMedia
                component="img"
                image={sticker.imageUrl}
                alt=""
                sx={{
                  objectFit: 'contain',
                  padding: 1,
                  height: '100%',
                }}
              />
            </Card>
          </Grid>
        ))}
      </Grid>

      <Dialog
        open={isEditing}
        onClose={() => setIsEditing(false)}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>Edit Package</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <Typography variant="h6" gutterBottom>
              Select Stickers
            </Typography>
            <Grid container spacing={2}>
              {mockStickers.map((sticker) => (
                <Grid item xs={6} sm={4} md={3} lg={2} key={sticker.id}>
                  <Card
                    onClick={() => handleStickerSelect(sticker.id)}
                    sx={{
                      cursor: 'pointer',
                      transition: 'all 0.2s ease-in-out',
                      border: selectedStickers.has(sticker.id) ? '2px solid #25D366' : 'none',
                      backgroundColor: selectedStickers.has(sticker.id) ? 'rgba(37, 211, 102, 0.1)' : 'transparent',
                      '&:hover': {
                        transform: 'scale(1.02)',
                        boxShadow: 3,
                      },
                      maxWidth: 120,
                      margin: '0 auto',
                      aspectRatio: '1',
                    }}
                  >
                    <CardMedia
                      component="img"
                      image={sticker.imageUrl}
                      alt=""
                      sx={{
                        objectFit: 'contain',
                        padding: 1,
                        height: '100%',
                      }}
                    />
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsEditing(false)}>Cancel</Button>
          <Button onClick={handleSave} variant="contained" color="primary">
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default StickerPackage; 