import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Navbar from './components/Navbar';
import StickerGallery from './components/StickerGallery';
import UserProfile from './components/UserProfile';
import StickerPackage from './components/StickerPackage';
import { StickerProvider } from './context/StickerContext';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#25D366',
    },
    secondary: {
      main: '#128C7E',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <StickerProvider>
        <Router>
          <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Navbar />
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
              <Routes>
                <Route path="/" element={<StickerGallery />} />
                <Route path="/profile" element={<UserProfile />} />
                <Route path="/package/:id" element={<StickerPackage />} />
              </Routes>
            </Box>
          </Box>
        </Router>
      </StickerProvider>
    </ThemeProvider>
  );
}

export default App; 