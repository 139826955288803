import React, { createContext, useContext, useState, ReactNode } from 'react';

interface Sticker {
  id: string;
  imageUrl: string;
}

interface StickerPackage {
  id: string;
  name: string;
  stickers: Sticker[];
  createdAt: Date;
}

interface StickerContextType {
  packages: StickerPackage[];
  createPackage: (stickers: Sticker[]) => void;
  deletePackage: (packageId: string) => void;
  updatePackage: (packageId: string, stickers: Sticker[]) => void;
  getPackage: (packageId: string) => StickerPackage | undefined;
}

const StickerContext = createContext<StickerContextType | undefined>(undefined);

export const StickerProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [packages, setPackages] = useState<StickerPackage[]>([]);

  const createPackage = (stickers: Sticker[]) => {
    const newPackage: StickerPackage = {
      id: Date.now().toString(),
      name: `Package ${packages.length + 1}`,
      stickers,
      createdAt: new Date(),
    };
    setPackages(prev => [...prev, newPackage]);
  };

  const deletePackage = (packageId: string) => {
    setPackages(prev => prev.filter(pkg => pkg.id !== packageId));
  };

  const updatePackage = (packageId: string, stickers: Sticker[]) => {
    setPackages(prev => prev.map(pkg => 
      pkg.id === packageId 
        ? { ...pkg, stickers, updatedAt: new Date() }
        : pkg
    ));
  };

  const getPackage = (packageId: string) => {
    return packages.find(pkg => pkg.id === packageId);
  };

  return (
    <StickerContext.Provider value={{ 
      packages, 
      createPackage, 
      deletePackage, 
      updatePackage,
      getPackage 
    }}>
      {children}
    </StickerContext.Provider>
  );
};

export const useStickerContext = () => {
  const context = useContext(StickerContext);
  if (context === undefined) {
    throw new Error('useStickerContext must be used within a StickerProvider');
  }
  return context;
}; 