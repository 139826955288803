import React, { useState } from 'react';
import {
  Grid,
  Card,
  CardMedia,
  Typography,
  Button,
  Box,
  TextField,
  Divider,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import { useStickerContext } from '../context/StickerContext';

interface Sticker {
  id: string;
  imageUrl: string;
}

// Generate array of 30 stickers using the actual files
const mockStickers: Sticker[] = Array.from({ length: 30 }, (_, index) => ({
  id: String(index + 1),
  imageUrl: `/stickers/sticker_${index + 1}.webp`,
}));

const StickerGallery = () => {
  const navigate = useNavigate();
  const { createPackage } = useStickerContext();
  const [selectedStickers, setSelectedStickers] = useState<Set<string>>(new Set());
  const [searchQuery, setSearchQuery] = useState('');

  const handleStickerSelect = (stickerId: string) => {
    const newSelected = new Set(selectedStickers);
    if (newSelected.has(stickerId)) {
      newSelected.delete(stickerId);
    } else {
      newSelected.add(stickerId);
    }
    setSelectedStickers(newSelected);
  };

  const handleCreatePackage = () => {
    const selectedStickerItems = mockStickers.filter(sticker => 
      selectedStickers.has(sticker.id)
    );
    createPackage(selectedStickerItems);
    setSelectedStickers(new Set());
    navigate('/profile');
  };

  const filteredStickers = mockStickers.filter(sticker => 
    sticker.id.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const selectedStickerItems = mockStickers.filter(sticker => 
    selectedStickers.has(sticker.id)
  );

  return (
    <Box>
      <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h4">Sticker Gallery</Typography>
        <Button
          variant="contained"
          color="primary"
          disabled={selectedStickers.size === 0}
          onClick={handleCreatePackage}
        >
          Create Package ({selectedStickers.size})
        </Button>
      </Box>

      <TextField
        fullWidth
        variant="outlined"
        placeholder="Search stickers..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        sx={{ mb: 2 }}
        InputProps={{
          startAdornment: <SearchIcon sx={{ mr: 1, color: 'text.secondary' }} />,
        }}
      />

      {selectedStickerItems.length > 0 && (
        <>
          <Typography variant="h6" sx={{ mb: 1 }}>
            Selected Stickers
          </Typography>
          <Grid container spacing={1} sx={{ mb: 3, px: 0.5 }}>
            {selectedStickerItems.map((sticker) => (
              <Grid item xs={4} sm={3} md={2} lg={1.5} key={sticker.id}>
                <Card
                  onClick={() => handleStickerSelect(sticker.id)}
                  sx={{
                    cursor: 'pointer',
                    transition: 'all 0.2s ease-in-out',
                    border: '2px solid #25D366',
                    opacity: 1,
                    '&:hover': {
                      transform: 'scale(1.02)',
                      boxShadow: 3,
                    },
                    maxWidth: 100,
                    margin: '0 auto',
                    aspectRatio: '1',
                  }}
                >
                  <CardMedia
                    component="img"
                    image={sticker.imageUrl}
                    alt=""
                    sx={{
                      objectFit: 'contain',
                      padding: 1,
                      height: '100%',
                    }}
                  />
                </Card>
              </Grid>
            ))}
          </Grid>
          <Divider sx={{ mb: 3 }} />
        </>
      )}

      <Typography variant="h6" sx={{ mb: 1 }}>
        All Stickers
      </Typography>
      <Grid container spacing={1} sx={{ px: 0.5 }}>
        {filteredStickers.map((sticker) => (
          <Grid item xs={4} sm={3} md={2} lg={1.5} key={sticker.id}>
            <Card
              onClick={() => handleStickerSelect(sticker.id)}
              sx={{
                cursor: 'pointer',
                transition: 'all 0.2s ease-in-out',
                border: selectedStickers.has(sticker.id) ? '2px solid #25D366' : 'none',
                opacity: selectedStickers.has(sticker.id) ? 0.5 : 1,
                '&:hover': {
                  transform: 'scale(1.02)',
                  boxShadow: 3,
                },
                maxWidth: 100,
                margin: '0 auto',
                aspectRatio: '1',
              }}
            >
              <CardMedia
                component="img"
                image={sticker.imageUrl}
                alt=""
                sx={{
                  objectFit: 'contain',
                  padding: 1,
                  height: '100%',
                }}
              />
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default StickerGallery; 